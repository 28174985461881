import React from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

function ContractUpdate() {

    return (
    <>
        <SEO title="Non-Standard Form Contract changes" 
            description="Non-Standard Contract changes, effective 1 July 2024"
            titleBespoke="true"
            meta={[{name:"robots", content:'noindex'}]}
        />

        <Header />
        <LayoutMain>

            <LayoutPanel background="mer-bg--primary-cyan"                 
                            padding="mer-py-lg"
                            theme="mer-theme--cyan">
                <div className="container">
                    <div className="row mer-panel justify-content-center align-items-middle">
                        <div className="col-12 mer-text--align-center mer-text--size-lg">
                            <h1 className="my-0 mer-typography--headline2">Find out more about Kleenheat's</h1>
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--gs-grey-light"      
                            border="mer-border--bottom"           
                            padding="mer-py-sm">
                <div className="container">
                    <div className="row mer-panel justify-content-center">
                        <div className="col-12 col-sm-10 col-lg-8 col-xl-7 mer-text--align-center">
                            <div className="content">
                                <h4>Non-Standard Contract changes, effective 1 July 2024</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--gs-grey-lighter"
                            padding="mer-pt-de mer-pb-lg">
                <div className="container">
                    <div className="row mer-panel justify-content-center">
                        <div className="col-12 col-sm-10 col-lg-8 col-xl-7 content">
                            <p>The Compendium of Gas Customer Licence Obligations (Customer Service Code) sets the standards and regulates the conduct of retailers and distributors who supply gas to residential and small business customers.</p>
                            <p>Recently, the Economic Regulation Authority approved updates to the Customer Service Code, and, as a result, we have revised our non-standard contract to align with these changes. The updated terms will apply to your gas plan with Kleenheat from <strong>1 July 2024</strong>.</p>
                            <p>Full versions of the new terms and conditions are available <a href="/wp-content/uploads/2024/06/NG_NSFC_TCs_Jul2024_FA.pdf" title="View new terms and conditions">here</a>. Alternatively, you can request a free paper copy by emailing us at&nbsp; 
                                <a href="mailto:nghome@kleenheat.com.au" title="Send us an email">nghome@kleenheat.com.au</a> or&nbsp; 
                                <a href="mailto:businesssales@kleenheat.com.au" title="Send us an email">businesssales@kleenheat.com.au</a> or by calling us on&nbsp; 
                                <a href="tel:132180" title="Phone us">13 21 80</a>.
                            </p>
                            <p>Below is a summary of the key updates to our terms. While this summary is not exhaustive, please be assured that all changes are aimed at enhancing protections and ensuring high service standards for you:</p>
                            <h5>Residential Customers and non-Residential Customers:</h5>
                            <p>
                                <ul>
                                    <li><strong>Advance Notice of Price Changes</strong>: We will provide you with at least 5 business days' notice before any changes to your rates, fees, or charges due to our decisions. Changes to your rates, fees or charges set by the State Government or those we pass directly to you from the gas distributor ATCO, will be communicated to you on or before your next bill.</li>

                                    <li><strong>Providing Estimate Basis Within a Timeframe</strong>: If we issue you an estimated bill due to the unavailability of an actual meter reading, we will inform you of the basis and reason for the estimate within 5 business days upon your request. If we need additional information from ATCO, we will inform you within 5 business days of receiving the necessary details.</li>      
                                </ul>
                            </p>
                            <h5>Residential Customers:</h5>
                            <p>
                                <ul>
                                    <li><strong>Support for Residential Customers Impacted by Family and Domestic Violence</strong>: We will provide additional privacy protections to our residential customers who are impacted by family and domestic violence, including offering secure communication channels and prioritising agreed-upon methods of communication. We will not disconnect a residential customer impacted by family and domestic violence for a period of at least nine months, and during this period and beyond, we will continually assess their situation to consider the implications of debt recovery and disconnection due to non-payment.</li>

                                    <li><strong>Extended Payment Assistance</strong>: We will offer payment assistance options, such as extensions and payment plans, to all residential customers upon request, with certain limitations in place. We will seek your consent before modifying payment plans, and all residential customers have the right to request a review of their plan. We will not charge a residential customer more than two late payment fees in relation to the same bill or more than 12 late payment fees within a 12-month period.</li>

                                    <li><strong>Minimum debt before disconnection</strong>: We will not disconnect service for residential customers with outstanding bills of less than $300, provided they have contacted us and agreed to a plan for paying the amount owed.</li>

                                    <li><strong>Waiver of Paper Bill Fees</strong>: Residential customers impacted by family and domestic violence or experiencing financial hardship will not be charged a fee for receiving paper bills.</li>                                  

                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </LayoutPanel>               

        </LayoutMain>
        <Footer />
    </>
    );
}

export default ContractUpdate;
